/* eslint-disable max-len */
import config from 'app/config';
import AccountApi, { ENDPOINTS as accountsEndpoints } from './account';
import AuthApi, { ENDPOINTS as authEndpoints } from './auth';
import BrandsApi, { ENDPOINTS as brandsEndpoints } from './brands';
import DataVisualizationApi, { ENDPOINTS as dataVisualizationEndpoints } from './dataVisualization';
import FeedsApi, { ENDPOINTS as feedsEndpoints } from './feeds';
import FirebaseApi, { ENDPOINTS as firebaseEndpoints } from './firebase';
import IntegrationsApi, { ENDPOINTS as integrationsEndpoints } from './integrations';
import InventoryApi, { ENDPOINTS as inventoryEndpoints } from './inventory';
import OrdersApi, { ENDPOINTS as ordersEndpoints } from './orders';
import ProductManagementApi, { ENDPOINTS as productManagementEndpoints } from './productManagement';
import PurchaseOrdersApi, { ENDPOINTS as purchaseOrdersEndpoints } from './purchaseOrders';
import SearchOrdersApi, { ENDPOINTS as searchOrdersEndpoints } from './searchOrders';
import ShipmentsApi, { ENDPOINTS as shipmentsEndpoints } from './shipments';
import SuppliersApi, { ENDPOINTS as suppliersEndpoints } from './suppliers';
import TestingApi, { ENDPOINTS as testingEndpoints } from './testing';
import TomApi, { ENDPOINTS as tomEndpoints } from './tom';
import UserManagementApi, { ENDPOINTS as userManagementEndpoints } from './userManagement';
import UtilitiesApi, { ENDPOINTS as utilitiesEndpoints } from './utilities';

export const accountApiSelector = () => new AccountApi(config.accountApi.url, accountsEndpoints);
export const authApiSelector = () => new AuthApi(config.authApi.url, authEndpoints);
export const brandsApiSelector = () => new BrandsApi(config.brandsApi.url, brandsEndpoints);
export const dataVisualizationApiSelector = () => new DataVisualizationApi(config.dataVisualizationApi.url, dataVisualizationEndpoints);
export const feedsApiSelector = () => new FeedsApi(config.feedsApi.url, feedsEndpoints);
export const firebaseApiSelector = () => new FirebaseApi(config.firebaseApi.url, firebaseEndpoints);
export const integrationsApiSelector = () => new IntegrationsApi(config.integrationsApi.url, integrationsEndpoints);
export const inventoryApiSelector = () => new InventoryApi(config.inventoryApi.url, inventoryEndpoints);
export const ordersApiSelector = () => new OrdersApi(config.ordersApi.url, ordersEndpoints);
export const orderLinesApiSelector = () => new OrdersApi(config.orderLinesApi.url, ordersEndpoints);

export const productManagementApiSelector = () => new ProductManagementApi(config.productManagementApi.url, productManagementEndpoints);
export const purchaseOrdersApiSelector = () => new PurchaseOrdersApi(config.purchaseOrdersApi.url, purchaseOrdersEndpoints);
export const searchOrdersApiSelector = () => new SearchOrdersApi(config.searchOrdersApi.url, searchOrdersEndpoints);
export const shipmentsApiSelector = () => new ShipmentsApi(config.shipmentsApi.url, shipmentsEndpoints);
export const suppliersApiSelector = () => new SuppliersApi(config.suppliersApi.url, suppliersEndpoints);
export const testingApiSelector = () => new TestingApi(config.testingApi.url, testingEndpoints);
export const tomApiSelector = () => new TomApi(config.tomApi.url, tomEndpoints);
export const userManagementApiSelector = () => new UserManagementApi(config.userManagementApi.url, userManagementEndpoints);
export const utilitiesApiSelector = () => new UtilitiesApi(config.utilitiesApi.url, utilitiesEndpoints);