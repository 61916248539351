import _get from 'lodash/get';
import { accountSettingsState } from '../selectors';

const businessState = state => _get(accountSettingsState(state), 'business');
export const getIsLoadingBusinessInfo = state =>
  _get(businessState(state), 'isLoadingBusinessInfo');
export const getIsSearchingZipCode = state => _get(businessState(state), 'isSearchingZipCode');
export const getIsUploadingBusinessLogo = state =>
  _get(businessState(state), 'isUploadingBusinessLogo');
export const getBusinessInfo = state => _get(businessState(state), 'businessInfo');
export const getBusinessInfoField = fieldId => state => _get(getBusinessInfo(state), fieldId);
export const getCountryList = state => _get(businessState(state), 'countryList');
