import { createAction } from 'redux-create-action';
import { ACTIONS } from './constants';

export const fetchUserProfile = createAction(
  ACTIONS.FETCH_USER,
  'username',
  'successActionCallback',
  'errorActionCallback',
);
export const setChangePassword = createAction(
  ACTIONS.SET_CHANGE_PASSWORD,
  'currentPassword',
  'newPassword',
  'successActionCallback',
  'errorActionCallback',
);
export const setUpdateUserProfile = createAction(
  ACTIONS.SET_UPDATE_USER_PROFILE,
  'username',
  'profileInfo',
  'successActionCallback',
  'errorActionCallback',
);
export const fetchBusinessInfo = createAction(
  ACTIONS.FETCH_BUSINESS_INFO,
  'successActionCallback',
  'errorActionCallback',
);
export const setUploadBusinessLogo = createAction(
  ACTIONS.SET_UPLOAD_BUSINESS_LOGO,
  'file',
  'successActionCallback',
  'errorActionCallback',
);
export const setUpdateBusinessInfo = createAction(
  ACTIONS.SET_UPDATE_BUSINESS_INFO,
  'updatedInfo',
  'successActionCallback',
  'errorActionCallback',
);
